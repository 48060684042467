import React from 'react';

const Equipe = ({ datas }) => {
  return (
    <li className={"color-" + datas.biome.toLowerCase()}>
      <h3>Team {datas.biome_sans}</h3>

      <p>Composée de :</p>

      <ul className="mates">
        {datas.mates.map((mates, index) => (
          <li className='mate' key={index}>{mates}</li>
        ))}
      </ul>
    </li>
  );
};

export default Equipe;