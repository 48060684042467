import React, { useEffect, useState } from 'react';

const Objectif = ({ datas }) => {

  const [isChecked, setIsChecked] = useState();

  useEffect(() => {
    if (localStorage.getItem('objectifs')) {
      if (localStorage.getItem('objectifs').split(",")[datas.id - 1] === 'true') {
        setIsChecked(true);
      }
      else {
        setIsChecked(false);
      }
    }
    else {
      setIsChecked(false);
    }
  }, [datas.id, isChecked])

  const handlerCheck = (ev, index) => {
    if (localStorage.getItem('objectifs')) {
      let checked_item = ev.target.checked;
      let actual = localStorage.getItem('objectifs').split(",");
      actual[index - 1] = checked_item;
      localStorage.setItem('objectifs', actual.join(","))
      setIsChecked(checked_item);
    }

  }

  return (
    <li>
      <input type="checkbox" id={"objectif-" + datas.id} name={"objectif-" + datas.id} defaultChecked={isChecked} onInput={(ev) => handlerCheck(ev, datas.id)} />
      <label htmlFor={"objectif-" + datas.id} className={isChecked === true ? 'checked' : ''}>
        <span className='checkbox'>
          <img src='./check.svg' alt="checked" />
        </span>
        <p>{datas.objectif}</p>
        <p>+{datas.points}</p>
      </label>
    </li>
  );
};

export default Objectif;