import React, { useEffect, useState } from 'react';
import ChronoTimer from '../components/ChronoTimer';
import Equipe from '../components/Equipe';
import Objectif from '../components/Objectif';
import Village from '../components/Village';

const Home = () => {
  const [checklistContainer, setChecklistContainer] = useState(false);
  const villages = [
    {
      id: 1,
      biome: "Plaines",
      biome_sans: "Plaines",
      mates: ["sibylliine", "resetstell"]
    },
    {
      id: 2,
      biome: "Désert",
      biome_sans: "Desert",
      mates: ["skinamic", "Kirbo_Midoriya"]
    },
    {
      id: 3,
      biome: "Taïga",
      biome_sans: "Taiga",
      mates: ["Colinspecteur", "Hades16"]
    },
    {
      id: 4,
      biome: "Savane",
      biome_sans: "Savane",
      mates: ["Lympea", "OtherxDreams"]
    },
    {
      id: 5,
      biome: "Glaces",
      biome_sans: "Glaces",
      mates: ["Taacti", "OysterBrain"]
    },
    {
      id: 6,
      biome: "Océan",
      biome_sans: "Ocean",
      mates: ["Super_Balmin", "Guesh_Xpert"]
    }
  ];
  const objectifs = [
    {
      id: 1,
      objectif: "Être équipé en full diamant",
      points: 1
    },
    {
      id: 2,
      objectif: "Aller dans le Nether",
      points: 1
    },
    {
      id: 3,
      objectif: "Avoir une plantation de citrouilles",
      points: 1
    },
    {
      id: 4,
      objectif: "Poser une table d’enchantement et la booster avec des bibliothèques",
      points: 1
    },
    {
      id: 5,
      objectif: "Avoir son propre cheval avec une selle",
      points: 1
    },
    {
      id: 6,
      objectif: "Créer la bannière de son village",
      points: 1
    },
    {
      id: 7,
      objectif: "Avoir plus de 20 PNJs dans son village",
      points: 1
    },
    {
      id: 8,
      objectif: "Prendre un screenshot d’une stalactite",
      points: 1
    },
    {
      id: 21,
      objectif: "Mettre deux skins en rapport avec le biome dans lequel vous êtes",
      points: 1
    },
    {
      id: 22,
      objectif: "Avoir une étoile du Nether",
      points: 1
    },
    {
      id: 23,
      objectif: "Trouver un trésor enfoui",
      points: 1
    },
    {
      id: 9,
      objectif: "Avoir un loup et le nommer comme un personnage des Simpson (ne compte pas si un autre binôme trouve le même nom)",
      points: 2
    },
    {
      id: 10,
      objectif: "Avoir un exemplaire de chaque bois du jeu",
      points: 2
    },
    {
      id: 11,
      objectif: "Faire atteindre le niveau expert à un de vos commerçants ",
      points: 2
    },
    {
      id: 12,
      objectif: "Faire un système redstone original",
      points: 2
    },
    {
      id: 13,
      objectif: "Relier votre village à un autre village (PNJ ou joueurs) par une route",
      points: 2
    },
    {
      id: 24,
      objectif: "Avoir un bloc de laine de chaque couleur",
      points: 2
    },
    {
      id: 25,
      objectif: "Utiliser un bloc provenant des 6 biomes plus haut",
      points: 2
    },
    {
      id: 14,
      objectif: "Trouver et exploiter un spawner à monstres",
      points: 3
    },
    {
      id: 15,
      objectif: "Obtenir une canne à pêche incassable",
      points: 3
    },
    {
      id: 16,
      objectif: "Remplir un coffre simple de stacks de diamants et/ou d’émeraudes",
      points: 3
    },
    {
      id: 17,
      objectif: "Faire atteindre le niveau maître à un de vos commerçants",
      points: 3
    },
    {
      id: 18,
      objectif: "Trouver le village d’un autre binôme",
      points: 3
    },
    {
      id: 19,
      objectif: "Capturer un ravageur",
      points: 3
    },
    {
      id: 20,
      objectif: "Trouver un manoir",
      points: 3
    },
    {
      id: 26,
      objectif: "Être équipé en full netherite",
      points: 3
    },
    {
      id: 27,
      objectif: "Être le premier binôme à battre l’Ender dragon",
      points: 3
    },
    {
      id: 28,
      objectif: "Être le binôme avec le moins de morts à la fin du défi",
      points: 3
    },
    {
      id: 29,
      objectif: "Posséder un catalyseur Sculk",
      points: 3
    },
    {
      id: 30,
      objectif: "Trouver l'Easter Egg du Spawn",
      points: 3
    }

  ];


  useEffect(() => {
    if (!localStorage.getItem("objectifs") || (localStorage.getItem("objectifs") && localStorage.getItem("objectifs").split(",").length < objectifs.length)) {
      let new_objectifs = [];
      for (let i = 0; i < objectifs.length; i++) {
        new_objectifs.push(false);
      }
      localStorage.setItem("objectifs", new_objectifs.join(","));
    }
  }, [])

  return (
    <div>
      <header className="parallax bg-1">
        <div className="container">
          <h1>Pimp My Town</h1>
          <ChronoTimer />
        </div>
      </header>
      <section>
        <div className="container">
          <h2>Tout savoir sur le defi</h2>
          <h3>Les regles</h3>
          <article>
            <div className='column'>
              <p>Le défi <strong>Pimp My Town</strong> commencera le <strong>samedi 29 octobre</strong> et se terminera le <strong>dimanche 18 décembre à 12h00</strong>.</p>
              <p>C'est un défi sur un serveur Minecraft éphémère qui accueillera <strong>6 équipes de 2 joueurs</strong> soit un total de 12 participants ! Chaque équipe sera téléportée dans <strong>un village PNJ dans lequel elle devra survivre</strong> (s'installer, construire sa base, etc.). La particularité de ce défi est qu'il faudra, en parallèle, <strong>améliorer le village qui vous accueille</strong> ainsi que la qualité de vie de ses habitants.</p>
            </div>
            <div className='column'>
              <p>A la fin du défi, le 18 décembre, chaque joueur mettra <strong>une note sur 5 à chaque village</strong> et une autre note sur 5 à chaque bases construites. <strong>Chaque équipe aura donc une note sur 100 !</strong> A ça s'ajoute une liste d'objectifs bonus pour obtenir d'autres points, disponible plus bas dans cette section.</p>
              <p className="red"><strong>Le PVP est autorisé et vous pouvez ramasser les items perdus par un joueur. Cependant, il est interdit de piller ou détruire le village d'un autre binôme !</strong></p>
            </div>
          </article>

          <h3>La liste des villages</h3>
          <ul className='villages'>
            {
              villages.map((village, index) => (
                <Village key={index} datas={village} />
              ))
            }
          </ul>

          <p><strong>Soyez créatifs ! Le niveau en build ne fait pas tout !</strong></p>

          <button onClick={(ev) => setChecklistContainer(true)}>Liste des objectifs bonus</button>

          <div className={checklistContainer === true ? 'objectifs visible' : 'objectifs'} onClick={(ev) => {
            if (ev.target.classList.contains("objectifs")) {
              setChecklistContainer(false);
            }

          }}>
            <div className='container'>
              <h3>Les objectifs bonus</h3>
              <ul className='checklist'>
                {
                  objectifs.map((objectif, index) => (
                    <Objectif key={index} datas={objectif} />
                  ))
                }
              </ul>
              <button onClick={(ev) => setChecklistContainer(false)}>Fermer</button>
            </div>

          </div>
        </div>
      </section>
      <section className="parallax bg-2">
        <div className="container">
          <h2>Les equipes</h2>
          <ul className='teams'>
            {
              villages.map((village, index) => (
                <Equipe key={index} datas={village} />
              ))
            }
          </ul>
        </div>
      </section>
      <section>
        <h2>Le serveur</h2>
        <ul>
          <li>
            <h3>Adresse :</h3>
            <p className="copy" onClick={(ev) => {
              let copyText = document.querySelector('.copy_hidden')
              copyText.select();
              copyText.setSelectionRange(0, 99999);
              navigator.clipboard.writeText(copyText.value);
              copyText.blur();

              ev.target.innerHTML = "<span class='green'>Adresse copiée !</span>"

              window.setTimeout(() => {
                document.querySelector('.copy').innerHTML = "GAME-FR-14.MTXSERV.COM:27200"
              }, 1000)
            }}>GAME-FR-14.MTXSERV.COM:27200</p>
            <input type="text" className="copy_hidden" value="GAME-FR-14.MTXSERV.COM:27200" />
          </li>
          <li>
            <h3>Version :</h3>
            <p>1.19</p>
          </li>
          <li>
            <h3>Difficulte :</h3>
            <p>Difficile</p>
          </li>
        </ul>
        <p className="red"><strong>Redémarrage quotidien du serveur tous les matins, à 04h00 environ.</strong></p>
      </section>
      <footer>
        <p>Développé en React JS par Théo Formet.</p>
      </footer>
    </div>
  );
};

export default Home;