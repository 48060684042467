import React from 'react';

const Village = ({ datas }) => {
  return (
    <li className={"bg-" + datas.biome.toLowerCase()}>
      <div className="text-content">
        <h4>Village #{datas.id}</h4>
        <p>Biome {datas.biome}</p>
      </div>
    </li>
  );
};

export default Village;