import React, { useEffect, useRef, useState } from 'react';

const ChronoTimer = () => {

  const dateNow = new Date();
  const dateStart = new Date("Oct 29 00:00:00 2022");
  const dateEnd = new Date("Dec 18 12:00:00 2022");
  const [timer, setTimer] = useState(parseInt((dateEnd - dateNow) / 1000));
  const [fullTimer, setFullTimer] = useState();
  const [prefix, setPrefix] = useState("Le défi se termine dans");
  let chrono = useRef(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const refreshTimer = () => {
    if (dateNow < dateStart) {
      setPrefix("Le défi commence le")
      setFullTimer("samedi 29 octobre");
      clearInterval(chrono);
    }
    else {
      let total_secondes = timer;
      console.log(total_secondes)
      if (total_secondes < 0) {
        setPrefix("");
        setFullTimer("");
        window.location.reload();
      }
      else {
        let jours = Math.floor(total_secondes / (60 * 60 * 24));
        if (jours < 10) {
          jours = "0" + jours;
        }
        let heures = Math.floor((total_secondes - (parseInt(jours) * 60 * 60 * 24)) / (60 * 60));
        if (heures < 10) {
          heures = "0" + heures;
        }
        let minutes = Math.floor((total_secondes - ((parseInt(jours) * 60 * 60 * 24 + parseInt(heures) * 60 * 60))) / 60);
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        let secondes = Math.floor(total_secondes - ((parseInt(jours) * 60 * 60 * 24 + parseInt(heures) * 60 * 60 + parseInt(minutes) * 60)));
        if (secondes < 10) {
          secondes = "0" + secondes;
        }

        let et = "";
        let mot_jour = "j";
        let mot_heure = "h";
        let mot_minute = "min";
        let mot_seconde = "s";

        setFullTimer(jours + ' ' + mot_jour + ' ' + heures + ' ' + mot_heure + ' ' + minutes + ' ' + mot_minute + ' ' + et + ' ' + secondes + ' ' + mot_seconde);
      }
    }
  }

  useEffect(() => {

    chrono.current = setInterval(() => {
      setTimer(timer - 1);
      refreshTimer()
    }, 1000);

    return () => clearInterval(chrono.current);
  }, [refreshTimer, timer])

  return (
    <div>
      {
        dateNow > dateEnd ?
          <p><strong>Le défi est terminé !</strong></p>
          :
          <p>{prefix} <strong>{fullTimer}</strong></p>
      }

    </div>
  );
};

export default ChronoTimer;